export const Popups = {
    state: () => ({
        isActiveSettings: false,
        isActiveCurrency: false,
        isActiveProductDetails: false,
        isActiveComplection: false,
        isActiveSlidingComplection: false,
        isActiveDoorComplection: false,
        isActiveAlert: false,
        alertTitle: '',
        alertContent: '',
        activeProduct: 0
    }),
    mutations: {
        toggleSettings(state) {
            state.isActiveSettings = !state.isActiveSettings;
        },
        toggleCurrency(state) {
            state.isActiveCurrency = !state.isActiveCurrency;
        },
        toggleProductDetails(state) {
            state.isActiveProductDetails = !state.isActiveProductDetails;
        },
        toggleComplection(state) {
            state.isActiveComplection = !state.isActiveComplection;
        },
        toggleSlidingComplection(state) {
            state.isActiveSlidingComplection = !state.isActiveSlidingComplection;
        },
        toggleSwingComplection(state) {
            state.isActiveSwingComplection = !state.isActiveSwingComplection;
        },
        toggleDoorComplection(state) {
            state.isActiveDoorComplection = !state.isActiveDoorComplection;
        },
        toggleAlert(state, data) {
            state.alertTitle = data.title;
            state.alertContent = data.content;
            state.isActiveAlert = !state.isActiveAlert;
        },
        setActiveProduct(state, id) {
            state.activeProduct = id;
            state.isActiveProductDetails = true;
        }
    },
    getters: {
        getProduct: (state, getters, rootState) => {
            try {
                // return rootState.products;
                return rootState.products.products.filter(product => product.id == Number(state.activeProduct));
            } catch (error) {
                return '';
            }

        }
    },
    namespaced: true
};